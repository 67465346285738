.sidebar-container {
  position: fixed;
  top: 0;
  /* left: 0; */
  padding: 10px;
  padding-left: 5px;
  padding-top: 56px;
  width: 210px;
  height: 100%;
  border-radius: 0;
  background-color: #fff;
  z-index: 1000;
}
.sidebar-container .b-fade {
  display: none;
}
.sidebar-container .b-menu {
  z-index: 3000;
  position: sticky;
  background-color: #fff;
  height: 100%;
  padding-top: 89px;
}
.sidebar-container .b-option {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}
.sidebar-container .b-option a {
  text-decoration: none;
  color: #000;
}
.sidebar-container .b-option i {
  margin-right: 10px;
  color: var(--primerColor);
}
.sidebar-container .b-option span {
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  letter-spacing: -0.5px;
  font-size: 14px;
}
.sidebar-container .btn-logOut {
  all: unset;
  cursor: pointer;
}
.sidebar-container .btn-logOut i {
  transform: rotate(180deg);
}
@media (max-width: 768.98px) {
  .sidebar-container {
    right: -300px;
    transition: all 0.25s;
    padding-right: 0;
  }
  .sidebar-container.open {
    right: 0px;
  }
  .sidebar-container .b-fade.open {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: red;
    background-color: rgba(32, 38, 45, 0.2);
    backdrop-filter: blur(2px);
  }
}
