@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,600;0,700;0,900;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Nunito", Sans-serif;
  background-color: #fff;
}
/* Global classes */
.cursorPointer {
  cursor: pointer;
}
:root {
  --primerColor: #f2f2f2;
  --segundoColor: #f2f2f2;
}
