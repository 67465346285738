.app-container {
  background-color: #ffffff;
}
.loading-container {
  display: none;
}
.loading-container.active {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.1);
}
.b-loading {
  position: fixed;
  top: 20px;
  background-color: #ffffff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0.149);
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b-loading img {
  max-width: 100%;
  max-height: 100%;
  width: 34px;
  height: 34px;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
