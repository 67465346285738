/* colors */
.colors {
  color: #1f3329;
  color: #a2d7c6;
  color: #ecf7f4;
  color: #542118;
}
.miCuenta-container .title {
  margin: 0;
}
.miCuenta-container .main-container {
  padding-top: 72px;
}
.miCuenta-container .rowUserData {
  margin-bottom: 30px;
}
.miCuenta-container .boxUserName span {
  font-weight: 800;
  color: #542118;
  font-size: 21px;
}
.miCuenta-container .box-puntos {
  width: 400px;
  /* height: 120px; */
  text-align: center;
  padding: 10px 50px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  /* border: 1px dashed #1f3329; */
  border-radius: 7px;
}
.miCuenta-container .subtitlePuntos {
  font-weight: 800;
  color: #542118;
  font-size: 21px;
}
.miCuenta-container .box-puntos .boxTxtPuntos {
  font-size: 30px;
  background-color: #fffcf9;
  border: 1px dashed #d9c6b0;
  border-radius: 100px;
  font-weight: 700;
  color: #a2d7c6;
  margin-top: 22px;
  width: 200px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}
.miCuenta-container .boxBtnCanjear {
  margin: 54px 0;
}
.miCuenta-container .btnCanjearPuntos {
  all: unset;
  margin: 0;
  padding: 0 3.5rem;
  height: 2.81rem;
  line-height: 2.8rem;
  border-radius: 7px;
  background-color: #542118;
  box-shadow: 0 4px 14px 0 rgb(84 33 24 / 39%);
  color: white;
  font-weight: 700;
  font-size: 21px;
  cursor: pointer;
  text-align: center;
}
.miCuenta-container .b-modal {
  width: 500px;
}
.miCuenta-container .b-modal .input-box {
  font-size: 30px;
  background-color: #fffcf9 !important;
  border: 1px dashed #d9c6b0;
  border-radius: 100px;
  font-weight: 700;
  color: #a2d7c6;
  margin-top: 10px;
  width: 200px;
  display: flex;
}
.miCuenta-container .b-modal .input-box .icon {
  width: 21px;
  font-weight: 900;
  color: #4c4c4c;
}
.miCuenta-container .b-modal .input-box input {
  all: unset;
  width: 100%;
  font-size: 21px;
  font-family: "Nunito", Sans-serif;
  font-weight: 900;
  border: 0;
  padding-left: 7px;
  color: #4c4c4c;
}
.miCuenta-container .canjearPuntosModal .input-box {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: 200px;
}
.miCuenta-container .canjearPuntosModal .input-box input {
  font-size: 36px;
  text-align: center;
  padding: 0;
}
.miCuenta-container .canjearPuntosModal .modal-title {
  margin: 5px;
}
.miCuenta-container .canjearPuntosModal .modal-text {
  margin: 5px;
}
.miCuenta-container .cuponCreado {
  font-size: 30px;
  background-color: #fffcf9 !important;
  border: 1px dashed #d9c6b0;
  border-radius: 5px;
  font-weight: 700;
  color: #a2d7c6;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81px;
  text-align: center;
  padding: 20px 5px;
  cursor: pointer;
}
.miCuenta-container .cupon {
  font-size: 30px;
  background-color: #fff !important;
  border: 1px dashed #d9c6b0;
  border-radius: 5px;
  font-weight: 700;
  color: #a2d7c6;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  text-align: center;
  padding: 20px 5px;
  cursor: pointer;
}
.miCuenta-container .fa-sign-out-alt {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .miCuenta-container .btnCanjearPuntos {
    width: 50%;
  }
  .miCuenta-container .title {
    font-size: 24px;
  }
  .miCuenta-container .cuponCreado {
    font-size: 24px;
  }
  .miCuenta-container .cupon {
    font-size: 24px;
  }
}
/* 
.miCuenta-container .box-container-title {
  display: flex;
  padding: 10px 0px;
  align-items: center;
}
.miCuenta-container .title {
  font-size: 21px;
  font-weight: 600;
  padding-left: 20px;
  color: #542118;
  font-weight: 900;
}

.miCuenta-container .box-puntos .text {
  color: #542118;

  font-weight: 700;
  margin-bottom: 10px;
}

.miCuenta-container .box {
  margin-top: 20px;
}
.main-container .row-userData {
  margin-top: 30px;
  border-radius: 5px;
}
.miCuenta-container .box-userData {
  margin-top: 45px;
}
.miCuenta-container .box-welcomeTxt {
  padding: 0;
}
.miCuenta-container .box-welcomeTxt span {
  font-weight: 500;
  color: #542118;
}
.miCuenta-container .box-userName span {
  font-weight: 700;
  color: #542118;
} */
