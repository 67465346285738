.alerta-container {
  position: fixed;
  left: -3000px;
}
.alerta-container.active {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  /* transition: all 0.5s; */
}
.alerta-container .b-fade {
  width: 100%;
  height: 100%;
  z-index: 5000;
}
.alerta-container .b-alerta {
  position: absolute;
  /* background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); */
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 9px;
  width: 50%;
  padding: 0;
  border-radius: 9px;
  z-index: 5000;
  top: -500px;
  /* bottom: 30px; */
  transition: all 0.5s;
}
.alerta-container.active .b-alerta {
  top: 10px;
  transition: all 0.5s;
}

/* success alert*/
.alerta-container .b-alerta.success {
  background-color: #eaf7ee;
  border: 1px solid #c4e5cd;
}
.alerta-container .b-alerta.success .alert-icon {
  color: #3cbb5e;
}
/* info alert*/
.alerta-container .b-alerta.info {
  background-color: #cce5ff;
  border: 1px solid #3d89db;
}
.alerta-container .b-alerta.info .alert-icon {
  color: #3d89db;
}
/* warning alert */
.alerta-container .b-alerta.warning {
  background-color: #fef7ea;
  border: 1px solid #f8e3bf;
}
.alerta-container .b-alerta.warning .alert-icon {
  color: #ee9500;
}

.alerta-container .b-alerta .b-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 54px;
  padding: 0 10px;
  padding-right: 22px;
}
.alerta-container .b-alerta .alert-icon {
  font-size: 21px;
  margin-right: 10px;
}
.alerta-container .b-alerta .b-body .b-massage {
  font-family: "Nunito", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.alerta-container .b-alerta .b-body .fa-times {
  position: absolute;
  right: 10px;
}
.alerta-container .b-alerta .b-footer button {
  all: unset;
  color: var(--primerColor);
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
@media (max-width: 598.99px) {
  .alerta-container .b-alerta {
    width: 95%;
  }
}
