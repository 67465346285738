.personalizacion-container {
  padding-right: 250px;
}
.personalizacion-container .MuiContainer-root {
  padding: 0;
  padding-left: 10px;
}
.personalizacion-container .title {
  margin-bottom: 5px;
}
.personalizacion-container .box-formulario {
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4); */
  border-radius: 9px;
  margin-bottom: 10px;
}
.personalizacion-container .box-formulario .formulario {
  padding: 0;
}
.personalizacion-container .box-formulario .form-item {
  margin-top: 10px;
}
.personalizacion-container .box-formulario .form-item.form-contenido {
  padding: 0 25px;
}
.personalizacion-container .box-formulario .form-item.form-contenido.hide {
  display: none;
}
.personalizacion-container .box-formulario .title {
  margin-top: 0;
}
.personalizacion-container .box-formulario h2.title {
  margin-top: 10px;
}
.personalizacion-container .box-formulario .box-name {
  display: flex;
  align-items: center;
}
.personalizacion-container .box-formulario .box-name .field-name {
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-right: 20px;
}
.personalizacion-container .box-formulario .box-name .title {
  margin-right: 20px;
}
.personalizacion-container .box-formulario .box-input {
  display: flex;
}
.personalizacion-container .box-formulario .box-banner {
  display: flex;
}
.personalizacion-container .box-formulario .box-banner .banner {
  width: 100%;
  max-width: 100%;
}
.personalizacion-container .box-formulario .b-input {
  width: 100%;
  display: flex;
}
.personalizacion-container .box-formulario .form-input {
  display: flex;
  align-items: center;
}
.personalizacion-container .box-formulario .form-area {
  all: unset;
  display: flex;
  background-color: #f5f2fa;
  width: 100%;
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  padding: 3px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}
.personalizacion-container .box-formulario .logo {
  width: 220px;
  height: 56px;
}
.box-formulario .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 12px;
}

.box-formulario .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.box-formulario .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.box-formulario .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -1px;
  bottom: -3.5px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  transition: 0.4s;
}

.box-formulario input:checked + .slider {
  background-color: var(--primerColor);
}

.box-formulario input:focus + .slider {
  box-shadow: 0 0 1px var(--primerColor);
}

.box-formulario input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.personalizacion-container .box-submit .btn-submit {
  all: unset;
  border-radius: 8px;
  background-color: var(--primerColor);
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 40px;
  padding: 0 15px;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 968.98px) {
  .personalizacion-container {
    padding-right: 0;
  }
}
@media (max-width: 768.98px) {
  .personalizacion-container .box-submit {
    display: flex;
    text-align: center;
  }
  .personalizacion-container .box-submit .btn-submit {
    width: 100%;
  }
}
