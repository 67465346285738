.modal-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
}
.simple-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-container.active {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
}

.modal-container .b-fade {
  width: 100%;
  height: 100%;
  z-index: 3000;
}
.b-modal {
  position: absolute;
  width: 270px;
  height: auto;
  z-index: 5000;
  border-radius: 5px;
  padding: 10px;
  border: 0;
}
.b-modal-aux {
  border-radius: 5px;
  border: 0;
  border-color: #ffffff;
  background-color: #ffffff;
  padding: 10px;
}
.b-modal .modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
.b-modal .modal-header i {
  font-size: 21px;
  cursor: pointer;
}
.b-modal .modal-body .modal-title {
  font-size: 18px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  text-align: center;
  line-height: 23px;
}
.b-modal .modal-body .MuiPaper-rounded {
  border-radius: 0;
}
.b-modal .modal-body .modal-text {
  font-size: 14px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}
.b-modal .modal-body img {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.b-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.b-modal .modal-footer .validar-btn {
  all: unset;
  background-color: var(--primerColor);
  border: 0;
  padding: 8px 18px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  font-family: "Nunito", Sans-serif;
}
.b-modal .input-box {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  background-color: #f5f2fa;
  border-radius: 9px;
  padding: 0 10px;
  padding-left: 20px;
  height: 40px;
}
.b-modal .input-box .icon {
  width: 14px;
  font-weight: 900;
}
.b-modal .input-box input {
  all: unset;
  width: 100%;
  font-size: 18px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  border: 0;
  padding-left: 7px;
  color: #c5c5c5;
}
@media (max-width: 568px) {
  .b-modal {
    width: 95%;
    max-width: 95%;
  }
}
