.auth-container .title {
  color: var(--primerColor);
}
.auth-container .title_2 {
  color: var(--primerColor);
}
.auth-container .text {
  font-weight: 700;
  /* color: #1f3329; */
  color: #000;
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
}
.auth-container .input {
  all: unset;
  background-color: #fff;
  border: 0;
  color: #1f3329;
  font-size: 21px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
  padding: 5px 10px;
  height: 36px;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.125);
  letter-spacing: 2px;
}
.auth-container .btn {
  all: unset;
  background-color: var(--primerColor);
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  height: 36px;
  padding: 5px 0;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  cursor: pointer;
}
.auth-container .box-login {
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%); */
  width: 500px;
  padding: 10px;
  /* background-color: #ecf7f4; */
}
