@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,600;0,700;0,900;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap);
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito", Sans-serif;
  background-color: #fff;
}
/* Global classes */
.cursorPointer {
  cursor: pointer;
}
:root {
  --primerColor: #f2f2f2;
  --segundoColor: #f2f2f2;
}

.app-container {
  background-color: #ffffff;
}
.loading-container {
  display: none;
}
.loading-container.active {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.1);
}
.b-loading {
  position: fixed;
  top: 20px;
  background-color: #ffffff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0.149);
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b-loading img {
  max-width: 100%;
  max-height: 100%;
  width: 34px;
  height: 34px;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.home-container {
}
.home-container .box-container.center {
  text-align: center;
}
.home-container .wallet-btns{
  padding: 10px 30px;
  margin: 10px;
  background-color: black;
  color: #fff;
  border: 0;
  border-radius: 18px;
  cursor: pointer;
}
.home-container .wallet-btns .apple{

}
.home-container .wallet-btns.google{
  background-color: blue;
}
.home-container .titulo1 {
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 36px;
  margin-bottom: 12px;
  line-height: 33px;
}
.home-container .titulo2 {
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
}
.home-container .texto1 {
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.home-container .banner {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}
.home-container .registrar-btn {
  margin-top: 54px;
  width: 270px;
  height: 45px;
  border: 0;
  border-radius: 9px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  background-color: var(--primerColor);
  cursor: pointer;
  margin-bottom: 30px;
}
@media (max-width: 598px) {
  .home-container .registrar-btn {
    width: 100%;
  }
}

.header-container {
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}
.header-container .box-container {
  display: flex;
  align-items: center;
  position: relative;
}
.header-container .header-logo img {
  max-width: 100%;
  max-height: 100%;
  width: 200px;
}
.header-container .b-header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.header-container .b-link {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-container .fa-bars {
  display: none;
}
.header-container .headerLink {
  text-decoration: none;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  color: var(--primerColor);
  padding: 5px 10px;
  background-color: #f5f2fa;
  border-radius: 8px;
  color: var(--primerColor);
  cursor: pointer;
}
@media (max-width: 768.98px) {
  .header-container .MuiContainer-root {
    padding: 10px 16px;
  }
  .header-container .fa-bars {
    display: block;
    position: absolute;
    right: 0;
    padding: 10px;
    background-color: #f5f2fa;
    border-radius: 8px;
    color: var(--primerColor);
    cursor: pointer;
  }
}

.login-container .title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.personalizacion-container {
  padding-right: 250px;
}
.personalizacion-container .MuiContainer-root {
  padding: 0;
  padding-left: 10px;
}
.personalizacion-container .title {
  margin-bottom: 5px;
}
.personalizacion-container .box-formulario {
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4); */
  border-radius: 9px;
  margin-bottom: 10px;
}
.personalizacion-container .box-formulario .formulario {
  padding: 0;
}
.personalizacion-container .box-formulario .form-item {
  margin-top: 10px;
}
.personalizacion-container .box-formulario .form-item.form-contenido {
  padding: 0 25px;
}
.personalizacion-container .box-formulario .form-item.form-contenido.hide {
  display: none;
}
.personalizacion-container .box-formulario .title {
  margin-top: 0;
}
.personalizacion-container .box-formulario h2.title {
  margin-top: 10px;
}
.personalizacion-container .box-formulario .box-name {
  display: flex;
  align-items: center;
}
.personalizacion-container .box-formulario .box-name .field-name {
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-right: 20px;
}
.personalizacion-container .box-formulario .box-name .title {
  margin-right: 20px;
}
.personalizacion-container .box-formulario .box-input {
  display: flex;
}
.personalizacion-container .box-formulario .box-banner {
  display: flex;
}
.personalizacion-container .box-formulario .box-banner .banner {
  width: 100%;
  max-width: 100%;
}
.personalizacion-container .box-formulario .b-input {
  width: 100%;
  display: flex;
}
.personalizacion-container .box-formulario .form-input {
  display: flex;
  align-items: center;
}
.personalizacion-container .box-formulario .form-area {
  all: unset;
  display: flex;
  background-color: #f5f2fa;
  width: 100%;
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  padding: 3px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}
.personalizacion-container .box-formulario .logo {
  width: 220px;
  height: 56px;
}
.box-formulario .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 12px;
}

.box-formulario .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.box-formulario .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  transition: 0.4s;
}

.box-formulario .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -1px;
  bottom: -3.5px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  transition: 0.4s;
}

.box-formulario input:checked + .slider {
  background-color: var(--primerColor);
}

.box-formulario input:focus + .slider {
  box-shadow: 0 0 1px var(--primerColor);
}

.box-formulario input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.personalizacion-container .box-submit .btn-submit {
  all: unset;
  border-radius: 8px;
  background-color: var(--primerColor);
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 40px;
  padding: 0 15px;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 968.98px) {
  .personalizacion-container {
    padding-right: 0;
  }
}
@media (max-width: 768.98px) {
  .personalizacion-container .box-submit {
    display: flex;
    text-align: center;
  }
  .personalizacion-container .box-submit .btn-submit {
    width: 100%;
  }
}

.editor-container {
  padding-right: 100px;
}
.editor-container .formulario {
  padding-top: 0;
}
.editor-container .box-formulario {
  padding-top: 10px;
  border-radius: 8px;
}
.editor-container .box-formulario .form-item {
  padding: 10px;
}
.editor-container .rc-md-editor {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 5px;
}
.editor-container .sec-html {
  display: none;
}
.editor-container .rc-md-editor .editor-container .sec-md .input {
  padding-bottom: 50px;
}
.editor-container .rc-md-editor .rc-md-navigation {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.editor-container .box-submit {
  padding: 10px;
}
.editor-container .btn-submit {
  all: unset;
  border-radius: 8px;
  background-color: var(--primerColor);
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 40px;
  padding: 0 15px;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 1098.98px) {
  .editor-container {
    padding-right: 0px;
  }
}

.sidebar-container {
  position: fixed;
  top: 0;
  /* left: 0; */
  padding: 10px;
  padding-left: 5px;
  padding-top: 56px;
  width: 210px;
  height: 100%;
  border-radius: 0;
  background-color: #fff;
  z-index: 1000;
}
.sidebar-container .b-fade {
  display: none;
}
.sidebar-container .b-menu {
  z-index: 3000;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  height: 100%;
  padding-top: 89px;
}
.sidebar-container .b-option {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}
.sidebar-container .b-option a {
  text-decoration: none;
  color: #000;
}
.sidebar-container .b-option i {
  margin-right: 10px;
  color: var(--primerColor);
}
.sidebar-container .b-option span {
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  letter-spacing: -0.5px;
  font-size: 14px;
}
.sidebar-container .btn-logOut {
  all: unset;
  cursor: pointer;
}
.sidebar-container .btn-logOut i {
  transform: rotate(180deg);
}
@media (max-width: 768.98px) {
  .sidebar-container {
    right: -300px;
    transition: all 0.25s;
    padding-right: 0;
  }
  .sidebar-container.open {
    right: 0px;
  }
  .sidebar-container .b-fade.open {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: red;
    background-color: rgba(32, 38, 45, 0.2);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
  }
}

.admin-container {
  font-family: "Nunito", Sans-serif;
  padding: 0;
  padding-top: 90px;
  background-color: #fff;
}
.admin-container .header-container {
  position: fixed;
  height: 56px;
  top: 0;
  left: 0;
  box-shadow: 0 1px 4px rgba(9, 30, 66, 0.13), 0 8px 16px rgba(9, 30, 66, 0.08);
  display: flex;
  z-index: 2000;
}
.admin-container .header-container .header-logo {
  display: flex;
}
.admin-container .header-container .header-logo img {
  max-height: 56px;
}
.admin-container .view-container {
  margin-left: 250px;
  padding: 10px;
  border-radius: 8px;
}
.admin-container .configBtn {
  all: unset;
  margin-top: 54px;
  width: 270px;
  height: 45px;
  border: 0;
  border-radius: 9px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  background-color: #1b75bc;
  cursor: pointer;
  margin-bottom: 30px;
  text-align: center;
}

@media (max-width: 768.98px) {
  .admin-container .view-container {
    margin-left: 0;
    padding: 20px 0;
  }
}

.modal-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
}
.simple-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-container.active {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
}

.modal-container .b-fade {
  width: 100%;
  height: 100%;
  z-index: 3000;
}
.b-modal {
  position: absolute;
  width: 270px;
  height: auto;
  z-index: 5000;
  border-radius: 5px;
  padding: 10px;
  border: 0;
}
.b-modal-aux {
  border-radius: 5px;
  border: 0;
  border-color: #ffffff;
  background-color: #ffffff;
  padding: 10px;
}
.b-modal .modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
.b-modal .modal-header i {
  font-size: 21px;
  cursor: pointer;
}
.b-modal .modal-body .modal-title {
  font-size: 18px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  text-align: center;
  line-height: 23px;
}
.b-modal .modal-body .MuiPaper-rounded {
  border-radius: 0;
}
.b-modal .modal-body .modal-text {
  font-size: 14px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}
.b-modal .modal-body img {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.b-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.b-modal .modal-footer .validar-btn {
  all: unset;
  background-color: var(--primerColor);
  border: 0;
  padding: 8px 18px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  font-family: "Nunito", Sans-serif;
}
.b-modal .input-box {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  background-color: #f5f2fa;
  border-radius: 9px;
  padding: 0 10px;
  padding-left: 20px;
  height: 40px;
}
.b-modal .input-box .icon {
  width: 14px;
  font-weight: 900;
}
.b-modal .input-box input {
  all: unset;
  width: 100%;
  font-size: 18px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  border: 0;
  padding-left: 7px;
  color: #c5c5c5;
}
@media (max-width: 568px) {
  .b-modal {
    width: 95%;
    max-width: 95%;
  }
}

/* colors */
.colors {
  color: #1f3329;
  color: #a2d7c6;
  color: #ecf7f4;
  color: #542118;
}
.miCuenta-container .title {
  margin: 0;
}
.miCuenta-container .main-container {
  padding-top: 72px;
}
.miCuenta-container .rowUserData {
  margin-bottom: 30px;
}
.miCuenta-container .boxUserName span {
  font-weight: 800;
  color: #542118;
  font-size: 21px;
}
.miCuenta-container .box-puntos {
  width: 400px;
  /* height: 120px; */
  text-align: center;
  padding: 10px 50px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  /* border: 1px dashed #1f3329; */
  border-radius: 7px;
}
.miCuenta-container .subtitlePuntos {
  font-weight: 800;
  color: #542118;
  font-size: 21px;
}
.miCuenta-container .box-puntos .boxTxtPuntos {
  font-size: 30px;
  background-color: #fffcf9;
  border: 1px dashed #d9c6b0;
  border-radius: 100px;
  font-weight: 700;
  color: #a2d7c6;
  margin-top: 22px;
  width: 200px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}
.miCuenta-container .boxBtnCanjear {
  margin: 54px 0;
}
.miCuenta-container .btnCanjearPuntos {
  all: unset;
  margin: 0;
  padding: 0 3.5rem;
  height: 2.81rem;
  line-height: 2.8rem;
  border-radius: 7px;
  background-color: #542118;
  box-shadow: 0 4px 14px 0 rgb(84 33 24 / 39%);
  color: white;
  font-weight: 700;
  font-size: 21px;
  cursor: pointer;
  text-align: center;
}
.miCuenta-container .b-modal {
  width: 500px;
}
.miCuenta-container .b-modal .input-box {
  font-size: 30px;
  background-color: #fffcf9 !important;
  border: 1px dashed #d9c6b0;
  border-radius: 100px;
  font-weight: 700;
  color: #a2d7c6;
  margin-top: 10px;
  width: 200px;
  display: flex;
}
.miCuenta-container .b-modal .input-box .icon {
  width: 21px;
  font-weight: 900;
  color: #4c4c4c;
}
.miCuenta-container .b-modal .input-box input {
  all: unset;
  width: 100%;
  font-size: 21px;
  font-family: "Nunito", Sans-serif;
  font-weight: 900;
  border: 0;
  padding-left: 7px;
  color: #4c4c4c;
}
.miCuenta-container .canjearPuntosModal .input-box {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: 200px;
}
.miCuenta-container .canjearPuntosModal .input-box input {
  font-size: 36px;
  text-align: center;
  padding: 0;
}
.miCuenta-container .canjearPuntosModal .modal-title {
  margin: 5px;
}
.miCuenta-container .canjearPuntosModal .modal-text {
  margin: 5px;
}
.miCuenta-container .cuponCreado {
  font-size: 30px;
  background-color: #fffcf9 !important;
  border: 1px dashed #d9c6b0;
  border-radius: 5px;
  font-weight: 700;
  color: #a2d7c6;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81px;
  text-align: center;
  padding: 20px 5px;
  cursor: pointer;
}
.miCuenta-container .cupon {
  font-size: 30px;
  background-color: #fff !important;
  border: 1px dashed #d9c6b0;
  border-radius: 5px;
  font-weight: 700;
  color: #a2d7c6;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  text-align: center;
  padding: 20px 5px;
  cursor: pointer;
}
.miCuenta-container .fa-sign-out-alt {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .miCuenta-container .btnCanjearPuntos {
    width: 50%;
  }
  .miCuenta-container .title {
    font-size: 24px;
  }
  .miCuenta-container .cuponCreado {
    font-size: 24px;
  }
  .miCuenta-container .cupon {
    font-size: 24px;
  }
}
/* 
.miCuenta-container .box-container-title {
  display: flex;
  padding: 10px 0px;
  align-items: center;
}
.miCuenta-container .title {
  font-size: 21px;
  font-weight: 600;
  padding-left: 20px;
  color: #542118;
  font-weight: 900;
}

.miCuenta-container .box-puntos .text {
  color: #542118;

  font-weight: 700;
  margin-bottom: 10px;
}

.miCuenta-container .box {
  margin-top: 20px;
}
.main-container .row-userData {
  margin-top: 30px;
  border-radius: 5px;
}
.miCuenta-container .box-userData {
  margin-top: 45px;
}
.miCuenta-container .box-welcomeTxt {
  padding: 0;
}
.miCuenta-container .box-welcomeTxt span {
  font-weight: 500;
  color: #542118;
}
.miCuenta-container .box-userName span {
  font-weight: 700;
  color: #542118;
} */

.auth-container .title {
  color: var(--primerColor);
}
.auth-container .title_2 {
  color: var(--primerColor);
}
.auth-container .text {
  font-weight: 700;
  /* color: #1f3329; */
  color: #000;
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
}
.auth-container .input {
  all: unset;
  background-color: #fff;
  border: 0;
  color: #1f3329;
  font-size: 21px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
  padding: 5px 10px;
  height: 36px;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.125);
  letter-spacing: 2px;
}
.auth-container .btn {
  all: unset;
  background-color: var(--primerColor);
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  height: 36px;
  padding: 5px 0;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  cursor: pointer;
}
.auth-container .box-login {
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%); */
  width: 500px;
  padding: 10px;
  /* background-color: #ecf7f4; */
}

.registro-container .MuiContainer-root {
  padding: 0;
}
.input-container {
  margin-bottom: 10px;
}
.form-alta {
  width: 100%;
}
.registro-title {
  font-size: 21px;
  color: #adadad;
  text-align: center;
  font-family: "Nunito", Sans-serif;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registro-title i {
  position: absolute;
  color: #000;
  left: 15px;
  cursor: pointer;
}

.label-input {
  all: unset;
  width: 100%;
  font-size: 14px;
  font-family: "Nunito", Sans-serif;
  border: 0;
  color: #c5c5c5;
}
.input-box input::-webkit-input-placeholder {
  color: #c5c5c5;
}
.input-box input:-ms-input-placeholder {
  color: #c5c5c5;
}
.input-box input::placeholder {
  color: #c5c5c5;
}
.btn-submit {
  width: 40%;
  font-size: 18px;
  font-family: "Nunito", Sans-serif;
  border: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 20px;
  letter-spacing: 1px;
  margin-top: 15px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .btn-submit {
    width: 100%;
  }
  .registro-title {
    margin-bottom: 10px;
  }
}

.formulario {
  width: 100%;
  padding-top: 30px;
}
.box-formulario label {
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 16px;
  color: #000;
  padding-left: 10px;
}
.formulario .box-input {
  display: flex;
}
.formulario .b-flex {
  display: flex;
}
.formulario .form-input {
  all: unset;
  display: flex;
  background-color: #f5f2fa;
  width: 100%;
  height: 40px;
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  padding: 3px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.formulario .form-input .MuiInput-formControl {
  width: 100%;
}
.formulario .form-input .MuiInput-formControl::before {
  display: none;
}
.formulario .form-input .MuiInput-formControl::after {
  display: none;
}
.formulario .form-input #date-picker-dialog {
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}
.formulario .box-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formulario .submit-btn {
  all: unset;
  width: 70%;
  height: 40px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  background-color: var(--primerColor);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.formulario .submit-btn:hover {
  background-color: var(--primerColor);
}

/* Date picker  */

.MuiPickersModal-dialogRoot {
  /* background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); */
  border-radius: 5px;
  font-family: "Nunito", Sans-serif !important;
}
.MuiPickersModal-dialogRoot .MuiPickersModal-dialog {
}
.MuiPickersModal-dialogRoot
  .MuiPickersCalendarHeader-switchHeader
  .MuiTypography-body1 {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot
  .MuiPickersMonthSelection-container
  .MuiPickersMonth-monthSelected {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  color: var(--primerColor);
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersCalendarHeader-daysHeader
  .MuiPickersCalendarHeader-dayLabel {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-day
  p {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-current {
  font-family: "Nunito", Sans-serif;
  font-weight: 800;
}
.MuiPickersModal-dialogRoot .MuiPickersDay-daySelected {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  background-color: var(--primerColor);
  color: #fff;
}
.MuiPickersModal-dialogRoot .MuiPickersDay-daySelected:hover {
  background-color: var(--primerColor);
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-daySelected
  .MuiPickersDay-current {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  background-color: var(--primerColor);
  color: #fff;
}
.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar {
  background-color: var(--primerColor);
}
.MuiPickersModal-dialogRoot .MuiTypography-subtitle1 {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot .MuiPickersToolbarText-toolbarTxt {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot .MuiPickersYear-root {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot .MuiPickersYear-yearSelected {
  font-family: "Nunito", Sans-serif;
  font-weight: 800;
  color: var(--primerColor);
}
.MuiPickersModal-dialogRoot .MuiDialogActions-root {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0.149);
}
.MuiPickersModal-dialogRoot
  .MuiDialogActions-root
  .MuiButton-textPrimary:first-child {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  color: var(--primerColor);
}
.MuiPickersModal-dialogRoot
  .MuiDialogActions-root
  .MuiButton-textPrimary:last-child {
  font-family: "Nunito", Sans-serif;
  font-weight: 800;
  color: var(--primerColor);
  font-size: 18px;
}
/* The container */
.formulario .b-checkBox {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

/* Hide the browser's default checkbox */
.formulario .b-checkBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.formulario .b-checkBox a {
  font-family: "Nunito", Sans-serif;
  color: #000;
  font-size: 16px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f5f2fa;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.formulario .b-checkBox:hover input ~ .checkmark {
  background-color: #dbdbdb;
}

/* When the checkbox is checked, add a blue background */
.formulario .b-checkBox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.formulario .b-checkBox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.formulario .b-checkBox .checkmark:after {
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
@media (max-width: 568px) {
  .box-formulario {
    padding: 0;
  }
  .formulario {
    padding-top: 0;
  }
}

.institucionales-container .b-title {
  display: flex;
  align-items: center;
  position: relative;
}
.institucionales-container .backLink {
  text-decoration: none;
  color: var(--primerColor);
}
.institucionales-container .backLink i {
  color: var(--primerColor);
  font-size: 21px;
}
.institucionales-container .title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 668.98px) {
  .institucionales-container .b-title {
    display: block;
  }
}

.alerta-container {
  position: fixed;
  left: -3000px;
}
.alerta-container.active {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  /* transition: all 0.5s; */
}
.alerta-container .b-fade {
  width: 100%;
  height: 100%;
  z-index: 5000;
}
.alerta-container .b-alerta {
  position: absolute;
  /* background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); */
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 9px;
  width: 50%;
  padding: 0;
  border-radius: 9px;
  z-index: 5000;
  top: -500px;
  /* bottom: 30px; */
  transition: all 0.5s;
}
.alerta-container.active .b-alerta {
  top: 10px;
  transition: all 0.5s;
}

/* success alert*/
.alerta-container .b-alerta.success {
  background-color: #eaf7ee;
  border: 1px solid #c4e5cd;
}
.alerta-container .b-alerta.success .alert-icon {
  color: #3cbb5e;
}
/* info alert*/
.alerta-container .b-alerta.info {
  background-color: #cce5ff;
  border: 1px solid #3d89db;
}
.alerta-container .b-alerta.info .alert-icon {
  color: #3d89db;
}
/* warning alert */
.alerta-container .b-alerta.warning {
  background-color: #fef7ea;
  border: 1px solid #f8e3bf;
}
.alerta-container .b-alerta.warning .alert-icon {
  color: #ee9500;
}

.alerta-container .b-alerta .b-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 54px;
  padding: 0 10px;
  padding-right: 22px;
}
.alerta-container .b-alerta .alert-icon {
  font-size: 21px;
  margin-right: 10px;
}
.alerta-container .b-alerta .b-body .b-massage {
  font-family: "Nunito", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.alerta-container .b-alerta .b-body .fa-times {
  position: absolute;
  right: 10px;
}
.alerta-container .b-alerta .b-footer button {
  all: unset;
  color: var(--primerColor);
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
@media (max-width: 598.99px) {
  .alerta-container .b-alerta {
    width: 95%;
  }
}

