.institucionales-container .b-title {
  display: flex;
  align-items: center;
  position: relative;
}
.institucionales-container .backLink {
  text-decoration: none;
  color: var(--primerColor);
}
.institucionales-container .backLink i {
  color: var(--primerColor);
  font-size: 21px;
}
.institucionales-container .title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 668.98px) {
  .institucionales-container .b-title {
    display: block;
  }
}
