.header-container {
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}
.header-container .box-container {
  display: flex;
  align-items: center;
  position: relative;
}
.header-container .header-logo img {
  max-width: 100%;
  max-height: 100%;
  width: 200px;
}
.header-container .b-header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.header-container .b-link {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-container .fa-bars {
  display: none;
}
.header-container .headerLink {
  text-decoration: none;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  color: var(--primerColor);
  padding: 5px 10px;
  background-color: #f5f2fa;
  border-radius: 8px;
  color: var(--primerColor);
  cursor: pointer;
}
@media (max-width: 768.98px) {
  .header-container .MuiContainer-root {
    padding: 10px 16px;
  }
  .header-container .fa-bars {
    display: block;
    position: absolute;
    right: 0;
    padding: 10px;
    background-color: #f5f2fa;
    border-radius: 8px;
    color: var(--primerColor);
    cursor: pointer;
  }
}
