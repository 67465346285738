.registro-container .MuiContainer-root {
  padding: 0;
}
.input-container {
  margin-bottom: 10px;
}
.form-alta {
  width: 100%;
}
.registro-title {
  font-size: 21px;
  color: #adadad;
  text-align: center;
  font-family: "Nunito", Sans-serif;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registro-title i {
  position: absolute;
  color: #000;
  left: 15px;
  cursor: pointer;
}

.label-input {
  all: unset;
  width: 100%;
  font-size: 14px;
  font-family: "Nunito", Sans-serif;
  border: 0;
  color: #c5c5c5;
}
.input-box input::placeholder {
  color: #c5c5c5;
}
.btn-submit {
  width: 40%;
  font-size: 18px;
  font-family: "Nunito", Sans-serif;
  border: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 20px;
  letter-spacing: 1px;
  margin-top: 15px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .btn-submit {
    width: 100%;
  }
  .registro-title {
    margin-bottom: 10px;
  }
}

.formulario {
  width: 100%;
  padding-top: 30px;
}
.box-formulario label {
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 16px;
  color: #000;
  padding-left: 10px;
}
.formulario .box-input {
  display: flex;
}
.formulario .b-flex {
  display: flex;
}
.formulario .form-input {
  all: unset;
  display: flex;
  background-color: #f5f2fa;
  width: 100%;
  height: 40px;
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  padding: 3px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.formulario .form-input .MuiInput-formControl {
  width: 100%;
}
.formulario .form-input .MuiInput-formControl::before {
  display: none;
}
.formulario .form-input .MuiInput-formControl::after {
  display: none;
}
.formulario .form-input #date-picker-dialog {
  font-family: "Nunito", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}
.formulario .box-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formulario .submit-btn {
  all: unset;
  width: 70%;
  height: 40px;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  background-color: var(--primerColor);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.formulario .submit-btn:hover {
  background-color: var(--primerColor);
}

/* Date picker  */

.MuiPickersModal-dialogRoot {
  /* background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); */
  border-radius: 5px;
  font-family: "Nunito", Sans-serif !important;
}
.MuiPickersModal-dialogRoot .MuiPickersModal-dialog {
}
.MuiPickersModal-dialogRoot
  .MuiPickersCalendarHeader-switchHeader
  .MuiTypography-body1 {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot
  .MuiPickersMonthSelection-container
  .MuiPickersMonth-monthSelected {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  color: var(--primerColor);
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersCalendarHeader-daysHeader
  .MuiPickersCalendarHeader-dayLabel {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-day
  p {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-current {
  font-family: "Nunito", Sans-serif;
  font-weight: 800;
}
.MuiPickersModal-dialogRoot .MuiPickersDay-daySelected {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  background-color: var(--primerColor);
  color: #fff;
}
.MuiPickersModal-dialogRoot .MuiPickersDay-daySelected:hover {
  background-color: var(--primerColor);
}
.MuiPickersModal-dialogRoot
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-daySelected
  .MuiPickersDay-current {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  background-color: var(--primerColor);
  color: #fff;
}
.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar {
  background-color: var(--primerColor);
}
.MuiPickersModal-dialogRoot .MuiTypography-subtitle1 {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot .MuiPickersToolbarText-toolbarTxt {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot .MuiPickersYear-root {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
}
.MuiPickersModal-dialogRoot .MuiPickersYear-yearSelected {
  font-family: "Nunito", Sans-serif;
  font-weight: 800;
  color: var(--primerColor);
}
.MuiPickersModal-dialogRoot .MuiDialogActions-root {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0.149);
}
.MuiPickersModal-dialogRoot
  .MuiDialogActions-root
  .MuiButton-textPrimary:first-child {
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  color: var(--primerColor);
}
.MuiPickersModal-dialogRoot
  .MuiDialogActions-root
  .MuiButton-textPrimary:last-child {
  font-family: "Nunito", Sans-serif;
  font-weight: 800;
  color: var(--primerColor);
  font-size: 18px;
}
/* The container */
.formulario .b-checkBox {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

/* Hide the browser's default checkbox */
.formulario .b-checkBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.formulario .b-checkBox a {
  font-family: "Nunito", Sans-serif;
  color: #000;
  font-size: 16px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f5f2fa;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.formulario .b-checkBox:hover input ~ .checkmark {
  background-color: #dbdbdb;
}

/* When the checkbox is checked, add a blue background */
.formulario .b-checkBox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.formulario .b-checkBox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.formulario .b-checkBox .checkmark:after {
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 568px) {
  .box-formulario {
    padding: 0;
  }
  .formulario {
    padding-top: 0;
  }
}
