.editor-container {
  padding-right: 100px;
}
.editor-container .formulario {
  padding-top: 0;
}
.editor-container .box-formulario {
  padding-top: 10px;
  border-radius: 8px;
}
.editor-container .box-formulario .form-item {
  padding: 10px;
}
.editor-container .rc-md-editor {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 5px;
}
.editor-container .sec-html {
  display: none;
}
.editor-container .rc-md-editor .editor-container .sec-md .input {
  padding-bottom: 50px;
}
.editor-container .rc-md-editor .rc-md-navigation {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.editor-container .box-submit {
  padding: 10px;
}
.editor-container .btn-submit {
  all: unset;
  border-radius: 8px;
  background-color: var(--primerColor);
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 40px;
  padding: 0 15px;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 1098.98px) {
  .editor-container {
    padding-right: 0px;
  }
}
