.admin-container {
  font-family: "Nunito", Sans-serif;
  padding: 0;
  padding-top: 90px;
  background-color: #fff;
}
.admin-container .header-container {
  position: fixed;
  height: 56px;
  top: 0;
  left: 0;
  box-shadow: 0 1px 4px rgba(9, 30, 66, 0.13), 0 8px 16px rgba(9, 30, 66, 0.08);
  display: flex;
  z-index: 2000;
}
.admin-container .header-container .header-logo {
  display: flex;
}
.admin-container .header-container .header-logo img {
  max-height: 56px;
}
.admin-container .view-container {
  margin-left: 250px;
  padding: 10px;
  border-radius: 8px;
}
.admin-container .configBtn {
  all: unset;
  margin-top: 54px;
  width: 270px;
  height: 45px;
  border: 0;
  border-radius: 9px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  background-color: #1b75bc;
  cursor: pointer;
  margin-bottom: 30px;
  text-align: center;
}

@media (max-width: 768.98px) {
  .admin-container .view-container {
    margin-left: 0;
    padding: 20px 0;
  }
}
