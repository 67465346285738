.home-container {
}
.home-container .box-container.center {
  text-align: center;
}
.home-container .wallet-btns{
  padding: 10px 30px;
  margin: 10px;
  background-color: black;
  color: #fff;
  border: 0;
  border-radius: 18px;
  cursor: pointer;
}
.home-container .wallet-btns .apple{

}
.home-container .wallet-btns.google{
  background-color: blue;
}
.home-container .titulo1 {
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 36px;
  margin-bottom: 12px;
  line-height: 33px;
}
.home-container .titulo2 {
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
}
.home-container .texto1 {
  /* font-family: "Work Sans", sans-serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.home-container .banner {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}
.home-container .registrar-btn {
  margin-top: 54px;
  width: 270px;
  height: 45px;
  border: 0;
  border-radius: 9px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  background-color: var(--primerColor);
  cursor: pointer;
  margin-bottom: 30px;
}
@media (max-width: 598px) {
  .home-container .registrar-btn {
    width: 100%;
  }
}
